<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr class="condition-row">
          <th>
            <v-tooltip
              top
              max-width="300px"
            >
              <template v-slot:activator="{ on: tooltip }">
                <div v-on="{ ...tooltip }">
                  <v-checkbox
                    v-model="readonly"
                    class="edit-checkbox"
                    color="cpdblue"
                    :on-icon="'mdi-pencil-outline'"
                    :off-icon="'mdi-pencil-off-outline'"
                  >
                    <template v-slot:label>
                      {{ sectionName }}
                      <div
                        class="row-handle align-center ml-3"
                      >
                        <v-icon
                          color="cpdblue"
                          size="medium"
                        >
                          mdi-information-variant-circle-outline
                        </v-icon>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
              </template>
              <div>
                <slot name="tooltip">
                  <v-spacer />
                </slot>
              </div>
            </v-tooltip>
          </th>
          <th
            v-if="!showQuantity"
            class="text-capitalize"
          >
            <v-tooltip
              top
              max-width="300px"
            >
              <template #activator="{ on }">
                <div
                  class="row-handle align-center"
                  v-on="on"
                >
                  <v-icon
                    color="cpdblue"
                    size="medium"
                  >
                    mdi-information-variant-circle-outline
                  </v-icon>
                  {{ enterSiteInformationBy }}
                </div>
              </template>
              <div>
                <span v-if="enterSiteInformationBy === 'area'">
                  <slot name="area-tooltip">
                    Sum of all planted areas plus hardscape and building area must equal overall site area.
                  </slot>
                </span>
                <span v-else>
                  <slot name="percentage-tooltip">
                    Percentage of all planted areas must equal 100%.
                  </slot>
                </span>
              </div>
            </v-tooltip>
          </th>
          <th
            v-if="showQuantity"
            class="text-capitalize"
          >
            Quantity
          </th>
          <th
            v-if="showPercentNative"
            class="text-capitalize"
          >
            <v-tooltip
              top
              max-width="300px"
            >
              <template #activator="{ on }">
                <div
                  class="row-handle align-center"
                  v-on="on"
                >
                  <v-icon
                    color="cpdblue"
                    size="medium"
                  >
                    mdi-information-variant-circle-outline
                  </v-icon>
                  % Native
                </div>
              </template>
              <slot name="native-tooltip" />
            </v-tooltip>
          </th>
          <th
            v-if="showCondition"
            class="text-capitalize"
          >
            <v-tooltip
              top
              max-width="300px"
            >
              <template #activator="{ on }">
                <div v-on="on">
                  Condition
                </div>
              </template>
              <slot name="condition-tooltip" />
            </v-tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="row in vModel">
          <SiteConditionRow
            :key="row.cpid"
            :v-model="row"
            :available-area="availableArea"
            :readonly="readonly"
            :show-quantity="showQuantity"
            :show-percent-native="showPercentNative"
            :show-condition="showCondition"
            :rules="rules"
            :enter-site-information-by="enterSiteInformationBy"
            @input="input"
          />
        </template>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import SiteConditionRow from './SiteConditionRow'

export default {
  name: 'SiteConditionSection',
  components: { SiteConditionRow },
  props: {
    availableArea: { type: Number, required: false, default: null },
    sectionName: { type: String, required: true },
    showPercentNative: { type: Boolean, default: false },
    showCondition: { type: Boolean, default: false },
    showQuantity: { type: Boolean, default: false },
    vModel: { type: Array, default: () => [] },
    enterSiteInformationBy: { type: String, default: '' },
    rules: { type: Object, default: () => {} }
  },
  data () {
    return {
      internalValue: {},
      readonly: true
    }
  },
  methods: {
    input (value) {
      this.internalValue = value
      this.$emit('input', value)
    }
  }
}
</script>
<style>
tr.condition-row > th:nth-child(2){
  width: 150px;
}
tr.condition-row > th:nth-child(3){
  width: 230px;
}
.edit-checkbox .v-label {
  font-size: 15px;
}
tr.condition-row th {
  font-size: 15px !important;
}

</style>
