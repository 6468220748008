<template>
  <v-col class="">
    <v-alert
      v-if="variable.name === 'growth_zone' && !hasBeenSet"
      type="info"
      color="success"
    >
      Don't forget to set your growth zone to get accurate sequestration calculations based on your project's location.
    </v-alert>
    <div
      class="d-flex align-center"
      style="margin-bottom: -7px;"
    >
      <span class="subtitle-2 text-capitalize mt-1">
        {{ prettifiedName }}
      </span>
      <v-dialog
        v-if="variable.description"
        v-model="showDescription"
        width="400px"
      >
        <template v-slot:activator="{ on: dialog }">
          <v-tooltip
            right
          >
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                icon
                small
                class="ml-3"
                v-on="isLongDescription ? { ...tooltip, ...dialog } : { ...tooltip }"
              >
                <v-icon
                  style="font-size: 17px;"
                  color="onyx"
                >
                  mdi-book-open-page-variant
                </v-icon>
              </v-btn>
            </template>
            {{ isLongDescription ? 'Click to learn more' : variable.description }}
          </v-tooltip>
        </template>
        <v-card>
          <v-card-title class="text-capitalize">
            {{ prettifiedName }}
          </v-card-title>
          <v-card-text>{{ variable.description }}</v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div
      v-if="variable.type === 'numeric' || typeof variable.value === 'number'"
    >
      <TextFieldAndSlider
        label=""
        :initial-value="selectedValue"
        :min="0"
        :max="100"
        icon="mdi-sprout"
        show-slider
        @change="value => selectedValue = value"
      />
    </div>
    <div
      v-else
      class="d-flex"
      :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : 'flex-row'"
    >
      <v-radio-group
        v-model="selectedValue"
        hide-details
        :row="$vuetify.breakpoint.xsOnly && $vuetify.breakpoint.width > 415"
      >
        <v-radio
          v-for="(option, index) of variable.options"
          :key="option"
          :value="option"
          :label="option"
          color="shamrock"
          hide-details
        >
          <template #label>
            <div
              class="mr-2 white--text"
              style="padding: 2px 7px; transform: translateY(-3px);"
              :style="{ 'background-color': ['#A6D6BE', '#43B681', '#0D9E62'][index] }"
            >
              {{ $t(`calculator.regions.${option}`) }}
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <img
        v-if="variable.image"
        :src="variable.image"
        style="width: 100%; max-height: 208px; mix-blend-mode: darken; object-fit: contain;"
        :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : 'mt-4'"
      >
    </div>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TextFieldAndSlider from '@/components/atoms/TextFieldAndSlider'
const { mapGetters: mapVersionGetters } = createNamespacedHelpers('projects/versions')

export default {
  name: 'VariableInput',
  components: {
    TextFieldAndSlider
  },
  props: {
    variable: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showDescription: false,
      hasBeenSet: false
    }
  },
  computed: {
    ...mapVersionGetters(['version']),
    selectedValue: {
      get () {
        return this.variable.value
      },
      async set (value) {
        const numericValue = Number(value)
        if (!Number.isNaN(numericValue)) {
          value = numericValue
        }
        await this.$axios.put(`/versions/${this.version._id}/variables`, {
          name: this.variable.name,
          value
        })
        this.hasBeenSet = true
        this.$emit('updated')
      }
    },
    isLongDescription () {
      return this.variable.description.length > 50
    },
    prettifiedName () {
      return this.variable.prettyName || (this.$te(`variables.${this.variable.name}`) ? this.$t(`variables.${this.variable.name}`) : this.variable.name.replace(/_/g, ' '))
    }
  },
  created () {
    this.hasBeenSet = this.variable.hasBeenSet
  }
}
</script>
