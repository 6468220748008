<template>
  <div class="white pl-2 pr-2">
    <v-expansion-panels
      v-model="panels"
      flat
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-2">
          <CategoryHeader :capitalized="false">
            Overburdened and Underserved Communities (US Only)
            <template v-slot:tooltip>
              See the US Government's Climate and Economic
              Justice Screening Tool in resources below
              for environmental, infrastructural, and
              demographic information about your area.
              Overburdened and underserved communities
              are marginalized by underinvestment and
              overburdened by pollution.
            </template>
          </CategoryHeader>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row align="stretch">
            <v-col
              cols="6"
              class="d-flex justify-center align-center"
            >
              <div v-if="!isProjectInValidRegion">
                This project is located in an area where climate justice data is not currently available.
              </div>
              <div v-else-if="isCEJSTUnderserved">
                The project is located within an <span class="underserved-text">underserved community</span>, according to the US Government's Climate and Economic Justice Screening Tool.
              </div>
              <div v-else>
                This project is not located within an underserved community, according to the US Government's Climate and Economic Justice Screening Tool.
              </div>
            </v-col>
            <v-col cols="6">
              <Map
                v-if="geoJSON"
                class="map"
                :geo-json="geoJSON"
              />
            </v-col>
          </v-row>
          <ValueWithDataBook
            :highlighted="true"
          >
            <template v-slot:body>
              <v-checkbox
                v-model="userSelectedUnderserved"
                class="pa-0 ma-0"
                label="Select if your site is located in an underserved community outside the United States, as defined by local authorities."
                color="shamrock"
                :hide-details="true"
              />
            </template>
            <template v-slot:tooltip>
              Select if your site falls within an overburdened
              and underserved community outside the USA,
              as designated by NGOs, local, national, or
              international authorities.
            </template>
          </ValueWithDataBook>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-2">
          <CategoryHeader>
            Community Engagement Spectrum
            <template v-slot:tooltip>
              Adapted from the Spectrum of Public
              Participation, developed by the International
              Association for Public Participation, see
              resources below. Ideally projects are
              designed with greater community engagement
              (right side of the spectrum), especially when
              located in overburdened and underserved
              communities.
            </template>
          </CategoryHeader>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group
            v-model="communityEngagement"
            hide-details
            @change="updateCommunityEngagement"
          >
            <v-row
              align-content="center"
              align="center"
            >
              <v-col cols="16">
                <v-img
                  src="/img/engagement_spectrum/engagementarrow.png"
                  width="100%"
                />
              </v-col>
            </v-row>
            <v-row
              align-content="center"
              align="center"
            >
              <v-col
                v-for="item in engagementTiles"
                :key="item"
                class="equal-column"
              >
                <RadioImage
                  :image-src="`/img/engagement_spectrum/${item}.png`"
                  :radio-value="item"
                />
              </v-col>
            </v-row>
          </v-radio-group>
          <div class="mb-4 mt-2 text-center body-2 small">
            <i>
              Select highest level of public engagement intended or achieved
            </i>
          </div>
          <div class="body-2">
            If your site is in an underserved community, extra care should be taken to collaborate with and support the local community, throughout the planning and design process.
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import CategoryHeader from '@/components/atoms/CategoryHeader.vue'

import Vue from 'vue'
import ValueWithDataBook from '@/components/molecules/ValueWithDataBook.vue'
import Map from '@/views/calculator/calculatorTab/benefits/Map.vue'
import { debounce } from '@/helpers/debounce'
import RadioImage from '@/components/molecules/RadioImage.vue'

const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')
const { mapGetters: mapVersionGetters, mapActions: mapVersionActions } = createNamespacedHelpers('projects/versions')

export default Vue.extend({
  components: {
    Map,
    ValueWithDataBook,
    CategoryHeader,
    RadioImage
  },
  data () {
    return {
      geoJSON: null,
      userSelectedUnderserved: null,
      communityEngagement: null,
      panels: [0, 1],
      engagementTiles: ['inform', 'consult', 'involve', 'collaborate', 'co-design']
    }
  },
  computed: {
    ...mapProjectGetters(['project', 'isCEJSTUnderserved']),
    ...mapVersionGetters(['version']),
    isProjectInValidRegion: function () {
      return this['project']?.location?.country === 'United States'
    }
  },
  watch: {
    userSelectedUnderserved (val) {
      return this.updateServer({
        attributes: {
          ...this.mergeAttributes(),
          userSelectedUnderserved: val
        }
      })
    }
  },
  async created () {
    this.userSelectedUnderserved = !!this['version'].attributes['userSelectedUnderserved']
    const { data } = await this.$axios.get(`/projects/${this['project']._id}/location`)
    this.communityEngagement = this['version'].attributes['communityEngagement']
    this.geoJSON = data
  },
  methods: {
    ...mapVersionActions(['fetchVersion', 'setVersion']),
    mergeAttributes () {
      return {
        ...this['version'].attributes
      }
    },
    updateServer: debounce(async function (partial) {
      await this.$axios.put(`/versions/${this['version']._id}`, partial)
      await this.fetchVersion({ id: this['version']._id })
    }, 200),
    updateCommunityEngagement: function (communityEngagement) {
      // there must be a way to do a partial update
      // TODO: fix the updateVersion controller to support nested updates.
      return this.updateServer({
        attributes: {
          ...this.mergeAttributes(),
          communityEngagement
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
  .equal-column {
    flex-basis: 20%; /* because 100% / 5 columns = 20% per column */
    max-width: 20%; /* set maximum width to be equal to desired width */
    padding: 4px;
  }
  .map {
    height: 200px !important;
  }
  .small {
    font-size: 0.8em !important;
  }
  .underserved-text {
    color: var(--v-accent-base);
  }
</style>
