<template>
  <v-col
    cols="12"
    :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
  >
    <div
      class="header body-1 pt-2 pb-3 px-3 onyx--text leaf d-flex flex-column"
    >
      Elements in project
      <span class="metal--text caption">
        {{ delta > 0 ? 'Highest to lowest carbon emissions' : 'Lowest to highest carbon sequestration' }} per unit
      </span>
    </div>
    <v-expansion-panels
      accordion
    >
      <v-expansion-panel
        v-for="({ element, alternatives }) in category.elements"
        :key="element._id"
      >
        <v-expansion-panel-header>
          <template v-slot="{ open }">
            <div
              class="caption"
            >
              {{ element.name }}<br>
              <span v-if="element.selectedMaterial">
                ({{ element.selectedMaterial.name }})
              </span>
            </div>
            <div style="text-align: right;">
              <span
                v-show="$vuetify.breakpoint.smAndUp"
                class="caption font-weight-regular ml-auto mr-2"
                style="margin-top: 2px;"
              >
                {{ delta > 0 ? 'Emissions' : 'Sequestration' }} in project:
              </span>
              <span
                v-show="$vuetify.breakpoint.smAndUp"
                :class="element.totalCo2Kg > 0 ? 'crimson--text' : 'shamrock--text'"
              >
                <strong>{{ (Math.round(Math.abs(element.totalCo2Kg))).toLocaleString() }} kgCO₂e</strong>
              </span>
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-show="$vuetify.breakpoint.xsOnly"
            class="mb-3"
          >
            <div
              class="caption font-weight-regular"
              style="margin-top: 2px;"
            >
              {{ delta > 0 ? 'Emissions' : 'Sequestration' }} in project:
            </div>
            <div
              :class="element.totalCo2Kg > 0 ? 'crimson--text' : 'shamrock--text'"
            >
              <strong>{{ (Math.round(Math.abs(element.totalCo2Kg))).toLocaleString() }} kgCO₂e</strong>
            </div>
          </div>
          <v-simple-table
            class="mb-4 suggestions-table"
          >
            <thead>
              <tr>
                <th class="pb-2 pt-0 px-0 text-xs-left">
                  <div class="title font-weight-regular">
                    Alternatives to consider
                  </div>
                  <span class="caption">
                    {{ delta > 0 ? 'Lowest to highest carbon emissions' : 'Highest to lowest carbon sequestration' }} per unit
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="alternativeElement of alternatives"
                :key="alternativeElement._id"
              >
                <td>{{ alternativeElement.name }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-col>
</template>

<script>
export default {
  name: 'ElementBreakdown',
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    massUnits () {
      return 'kg'
    },
    delta () {
      if (this.category.elements[0]?.element?.totalCo2Kg) {
        return Math.sign(this.category.elements[0].element.totalCo2Kg)
      }
      return 1
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  // width: fit-content;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: -5px;
}
.v-expansion-panel::before {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.1), 0px 2px 1px 0px rgba(0, 0, 0, 0.07), 0px 2px 3px 0px rgba(0, 0, 0, 0.06);
}
.v-expansion-panel-content__wrap {
  padding: 0 16px 16px;
}
</style>
