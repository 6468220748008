<template>
  <div
    v-intersect.once="{
      handler: () => $emit('intersect'),
      options: {
        threshold: [0, 0.5, 1.0]
      }
    }"
    class="white"
  >
    <v-text-field
      v-model="searchText"
      v-intro="'Search for a specific material with the search bar.'"
      v-intro-step="4"
      v-intro-position="'right'"
      append-icon="mdi-magnify"
      :label="`Search for ${type}s`"
      single-line
      hide-details
      class="pt-0"
    />
    <v-row>
      <v-col>
        <v-select
          v-model="selectedCategory"
          v-intro="'Or use the dropdown to select a category.'"
          v-intro-step="5"
          v-intro-position="'right'"
          placeholder="Category"
          :items="categories"
          style="text-transform: capitalize"
          item-value="name"
          item-text="name"
          hide-details
          clearable
          class="mb-4 category-dropdown"
        >
          <template #item="{ item }">
            <!-- <BrandLogo
              v-if="item.brand"
              :brand="item.brand"
              size="35"
              class="mr-3"
              style="background: yellow;"
            /> -->
            <span class="category-dropdown-item">{{ item.name }}</span>
          </template>
        </v-select>
        <v-select
          v-if="hasSubCategories"
          ref="sub_dropdown"
          v-model="selectedSubCategory"
          style="text-transform: capitalize"
          :items="subCategories"
          hide-details
          clearable
          class="mb-4 sub-category"
        >
          <template v-slot:item="{ item }">
            <div style="text-transform: capitalize">
              {{ item }}
            </div>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <div
      v-if="!searchText && !selectedCategory"
      class="mb-3 d-flex justify-center"
    >
      <div>
        Search or select a category to load available {{ type }}s
        <p class="text-caption font-weight-light text--secondary">
          The {{ type }}s will be sorted from least to most emissions.
        </p>
      </div>
    </div>
    <div
      v-else-if="!searchText && selectedCategory && hasSubCategories && !selectedSubCategory"
      class="mb-3 d-flex justify-center"
    >
      Select a sub-category to load available furnishings
    </div>
    <v-row v-else>
      <v-col class="py-0">
        <v-tooltip
          right
          :value="clicked && selectedCategory"
        >
          <template #activator="{ on }">
            <!--
               This size was
               :height="$vuetify.breakpoint.xsOnly ? undefined : `calc(100vh - 475px)`"
               I think that it has to do with mobile, but i can't see any different.
               If things look strange revert the :height
            -->
            <v-list
              :height="$vuetify.breakpoint.xsOnly ? undefined : `auto`"
              :max-height="$vuetify.breakpoint.xsOnly ? 'calc(100vh - 380px)' : undefined"
              style="overflow: auto; transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);"
              v-on="clicked ? undefined : on"
            >
              <v-list-item
                v-for="(template, i) in filteredTemplates"
                :key="i"
                @click="() => handleTemplateSelect(template, i)"
              >
                <v-list-item-avatar
                  v-if="template.manufacturer"
                  tile
                  :size="35"
                >
                  <BrandLogo
                    :brand="template.manufacturer"
                    size="35"
                    style="background: yellow;"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-progress-linear
                    v-if="adding === i"
                    indeterminate
                  />
                  <v-list-item-title
                    v-if="adding !== i"
                    style="white-space: normal;"
                  >
                    <!-- <span v-if="template.manufacturer">
                      <strong>{{ template.manufacturer }}</strong>
                      <span> -</span>
                    </span> -->
                    <span>{{ template.name }}</span>
                    <v-chip
                      v-if="isCustomTemplate(template)"
                      class="mx-2 mb-1"
                      color="success"
                      x-small
                      outlined
                    >
                      Custom
                    </v-chip>
                    <v-btn
                      v-if="isCustomTemplate(template)"
                      icon
                      style="float: right"
                      aria-label="Delete"
                      x-small
                      @click.stop="deleteCustomTemplate(template, i)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="isCustomTemplate(template)"
                      icon
                      style="float: right"
                      aria-label="Edit"
                      x-small
                      @click.stop="editCustomTemplate(template, i)"
                    >
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="template.manufacturer">
                    {{ template.category }}
                  </v-list-item-subtitle>
                  <!-- <v-list-item-subtitle v-if="adding !== i">
                    {{ firstLetterUppercase(template.category) }}
                  </v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
          Now select an item from the results
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row v-if="searchText || (selectedCategory && hasSubCategories ? selectedSubCategory : true)">
      <v-col
        class="d-flex align-center px-6"
        :class="$vuetify.breakpoint.xsOnly && 'flex-column'"
      >
        <span class="mb-1">
          Can't find what you're looking for?
        </span>
        <v-btn
          rounded
          elevation="0"
          class="mx-3"
          :loading="adding === 'custom'"
          @click="customElementFormVisible = true"
        >
          Create a custom {{ type }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="customElementFormVisible"
      width="600"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
    >
      <component
        :is="customFormComponentName"
        :key="customElementFormVisible"
        :visible="customElementFormVisible"
        :element="customElement"
        :category="selectedCategory"
        :categories="customCategories"
        @create-element="createCustomElement"
        @update-element="updateCustomElement"
        @close="handleCloseCustomElementForm"
      />
    </v-dialog>
    <v-dialog
      v-model="shrubFormVisible"
      width="600"
      scrollable
    >
      <ShrubForm
        :adding-shrubs="adding === 'shrubs'"
        @discard="shrubFormVisible = false"
        @save="quantities => addShrubs(quantities)"
      />
    </v-dialog>
  </div>
</template>

<script>
import firstLetterUppercase from '@/helpers/firstLetterUppercase'
import CustomMaterialForm from './customForms/CustomMaterialForm'
import CustomOperationForm from './customForms/CustomOperationForm'
import CustomPlantForm from './customForms/CustomPlantForm'
import ShrubForm from './customForms/ShrubForm'
import { mixin as clickaway } from 'vue-clickaway2'

import BrandLogo from '@/components/atoms/BrandLogo'

import { mapState, mapActions, createNamespacedHelpers } from 'vuex'

import orderBy from 'lodash/orderBy'
import { captureMessage } from '@sentry/vue'
import { setupFuse } from '@/helpers/fuzzySearch'

const { mapGetters: mapProjectGetters } = createNamespacedHelpers('projects')
const { mapGetters: mapListGetters } = createNamespacedHelpers('lists')
const { mapGetters: mapVersionGetters, mapState: mapVersionState } = createNamespacedHelpers('projects/versions')

const emptyElementSchema = {
  name: '',
  category: '',
  description: ''
}

export default {
  $_veeValidate: {
    validator: 'new'
  },
  name: 'NewElementForm',
  components: {
    CustomMaterialForm,
    CustomOperationForm,
    CustomPlantForm,
    ShrubForm,
    BrandLogo
  },
  mixins: [clickaway],
  props: {
    type: {
      type: String,
      required: true
    },
    addingElements: {
      type: Boolean,
      required: true
    },
    clicked: {
      type: Boolean,
      default: false
    },
    cpdVersion: {
      type: Number,
      default: 2
    },
    eventKey: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      templates: [],
      templateFetchErrors: [],
      isTemplate: true,
      elementName: '',
      selectedCategory: null,
      selectedSubCategory: null,
      element: emptyElementSchema,
      co2PerQuantity: null,
      impactType: null,
      showFilters: false,
      searchText: '',
      fuse: null,
      firstLetterUppercase,
      customElement: null,
      customElementFormVisible: false,
      shrubFormVisible: false,
      adding: null
    }
  },
  computed: {
    ...mapState(['systemOfMeasurement']),
    ...mapVersionState(['hasShrubs']),
    ...mapProjectGetters(['project']),
    ...mapListGetters(['allElementCategories']),
    ...mapVersionGetters(['version']),
    customFormComponentName () {
      // when the tab changes fetch the associated templates.
      this.fetchTemplates()
      switch (this.type) {
        case 'material':
          return 'CustomMaterialForm'
        case 'plant':
          return 'CustomPlantForm'
        case 'operation':
        default:
          return 'CustomOperationForm'
      }
    },
    typesForFilters () {
      if (this.type === 'operation') {
        return ['maintenance', 'hydrozone']
      } else if (this.type === 'plant') {
        return ['plant']
      } else {
        return [this.type]
      }
    },
    categories () {
      // filter the categories by the templates that are found.
      const unorderedCategories = this.allElementCategories.filter((category) =>
        ((this.cpdVersion === 3 && category.cpdVersion === 3) ||
          (this.cpdVersion === 2 && category.cpdVersion !== 3)) &&
        this.typesForFilters.includes(category.type)
      )
      return orderBy(unorderedCategories, [category => category.name.toLowerCase()], ['asc'])
    },
    v3Categories () {
      return orderBy(
        this.allElementCategories.filter((category) => category.cpdVersion === 3 && this.typesForFilters.includes(category.type)),
        [category => category.name.toLowerCase()], ['asc']
      )
    },
    customCategories () {
      return this.version?.cpdVersions.includes(3) ? this.v3Categories : this.categories
    },
    subCategories () {
      if (!this.selectedCategory) {
        return null
      }
      return this.categories.find((x) => x.name === this.selectedCategory)?.subCategories
    },
    hasSubCategories () {
      if (!this.selectedCategory) {
        return false
      }
      return this.categories.find((x) => x.name === this.selectedCategory)?.subCategories?.length > 1 || false
    },
    filteredTemplates () {
      let templates = this.templates

      if (!this.hasShrubs) {
        // hide fertilizer when project has no shrubs - see https://trello.com/c/52SKh1q3/38-fix-issue-with-fertilizer-inputs
        templates = templates.filter((element) => !element.name.toLowerCase().includes('applied npk'))
      }
      if (this.searchText && this.fuse) {
        templates = this.fuse.search(this.searchText).map(result => result.item)
      }
      if (this.type) {
        templates = templates.filter(template => this.typesForFilters.includes(template.type))
      }
      if (this.selectedCategory) {
        if (this.selectedCategory && this.selectedSubCategory && this.hasSubCategories) {
          templates = templates.filter(template => {
            return template.category.toLowerCase() === this.selectedCategory.toLowerCase() &&
              (template.suggestionsCategory === this.selectedSubCategory || this.isCustomTemplate(template))
          })
        } else {
          templates = templates.filter(template => template.category.toLowerCase() === this.selectedCategory.toLowerCase())
        }
      } else {
        templates.filter(template => this.isCustomTemplate(template))
      }
      return templates
    }
  },
  watch: {
    selectedCategory (category) {
      if (category && category.toLowerCase() === 'shrubs') {
        this.shrubFormVisible = true
      }
      this.selectedSubCategory = null
      this.element = emptyElementSchema
    },
    subCategories (val) {
      if (val) {
        setTimeout(() => {
          if (this.$refs.sub_dropdown) {
            this.$refs.sub_dropdown.$el.querySelector('input').click()
            setTimeout(() => {
              this.selectedSubCategory = val[0]
            }, 100)
          }
        }, 300)
      }
    },
    addingElements (val) {
      if (!val) {
        this.adding = null
        this.shrubFormVisible = false
      }
    },
    typesForFilters: {
      immediate: true,
      async handler (type) {
        this.searchText = ''
        this.selectedCategory = null
      }
    },
    eventKey () {
      // this is updated when changes are made to templates that are saved in Calculator.vue
      this.fetchTemplates()
    },
    customElementFormVisible (value) {
      if (!value) {
        this.customElement = null
      }
    }
  },
  methods: {
    ...mapActions(['showSnackbar']),
    async fetchTemplates () {
      try {
        let { data: templates } = await this.$axios.get('/templates', {
          params: {
            withcustom: true,
            userId: this.project.user,
            typesOfTab: this.typesForFilters
          }
        })

        this.templates = templates.filter((template) => {
          if (template.isCustomTemplate) return true
          if (this.cpdVersion === undefined || this.cpdVersion === 2) {
            return template.cpdVersion === 2 || template.cpdVersion === undefined
          } else {
            if (template.category?.toLowerCase() === 'furnishings') {
              return true
            }
            return template.cpdVersion === this.cpdVersion
          }
        })
        this.templates = orderBy(this.templates, [template => template.name.toLowerCase()], ['asc'])
        this.fuse = setupFuse(this.templates)
        this.templateFetchErrors = []
      } catch (err) {
        await this.showSnackbar({ color: 'error', text: 'Whoops, error loading templates. Please try refreshing the page.' })
        this.templateFetchErrors = ['Error loading templates.']
        throw err
      }
    },
    deleteLayout (i) {
      this.element.layouts.splice(i, 1)
    },
    handleCloseCustomElementForm () {
      this.customElementFormVisible = false
      this.customElement = null
      return true
    },
    handleTemplateSelect (template, index) {
      this.$emit('add-elements', template, true)
      this.adding = index
    },
    isCustomTemplate (template) {
      return !template.isTemplate && template.isCustomTemplate
    },
    async addShrubs (quantities) {
      const elements = []
      for (const type in quantities) {
        // loop through each type of foliage
        const shrubTypes = quantities[type]
        for (const shrubType in shrubTypes) {
          const [quantity, ccId] = shrubTypes[shrubType]
          // loop through the sizes in each foliage type
          // find the element in the templates
          let shrubElement
          if (this.cpdVersion === undefined || this.cpdVersion === 2) {
            shrubElement = this.templates.find(template => (
              template.category?.toLowerCase() === 'shrubs' && template.name.toLowerCase().includes(type) && template.name.toLowerCase().includes(shrubType))
            )
          } else {
            shrubElement = this.templates.find(template => {
              return template.category?.toLowerCase() === 'shrubs' && template.carbonConscienceIds?.includes(ccId)
            })
          }
          if (!shrubElement) {
            if (this.cpdVersion !== 3) {
              throw new Error('No element found for: ', type, shrubType)
            }
            // v3 shrubs are not complete, for  now log that the shrub isn't found
            captureMessage(`No element found for ({shrubSize: ${type}, shrubType: ${shrubType}, ccId: ${ccId}})`)
          } else {
            // update first layout (count) with number of shrubs
            shrubElement.layouts[0].dimensions[0].value = quantity
            elements.push(shrubElement)
          }
        }
      }
      this.$emit('add-elements', elements)
      this.adding = 'shrubs'
    },
    createCustomElement (element) {
      // add this project to the element
      this.customElementFormVisible = false
      if (!element._id) {
        this.$emit('add-elements', element, true, () => this.fetchTemplates())
      }
      this.adding = 'custom'
      this.customElement = null
    },
    updateCustomElement (element) {
      // add this project to the element
      this.customElementFormVisible = false
      if (element._id) {
        this.$emit('update-element', element, true, () => this.fetchTemplates())
      }
      this.adding = 'custom'
      this.customElement = null
    },
    deleteCustomTemplate (element) {
      this.$emit('delete-element', element, () => this.fetchTemplates())
      this.customElement = null
    },
    editCustomTemplate (element) {
      this.customElement = JSON.parse(JSON.stringify(element))
      this.customElementFormVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>

.sub-category * {
  text-transform: capitalize;
}
.category-dropdown, .category-dropdown-item {
  text-transform: capitalize;
}
.tooltip {
  opacity: 0;
  transition: opacity 1s ease 0.25s;
  z-index: 3;
  top: 53%;
  left: calc(100% + 10px);
  transform: translateY(-50%);
  position: absolute;
  pointer-events: none;
  background: rgba(97, 97, 97, 0.9);
  width: 300px;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  padding: 7px 13px;
  &::after {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    // draw arrow
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid rgba(97, 97, 97, 0.9);
  }
}
</style>
