export const ecosystems = [
  { cpdId: 'BEX-BF', name: 'Boreal Forest' },
  { cpdId: 'BEX-DF', name: 'Dry Forest (Mediterranean)' },
  { cpdId: 'BEX-DM', name: 'Dry Meadows' },
  { cpdId: 'BEX-FSPS', name: 'Forested or Shrubby Peat Swamp' },
  // The following should not show up on the existing list.
  // { cpdId: 'BEX-GARD', name: 'Temperate Mixed Tree, Shrub and Forb Landscape ("Garden")' },
  { cpdId: 'BEX-MF', name: 'Mangrove Forest' },
  { cpdId: 'BEX-PG', name: 'Prairie Grassland' },
  { cpdId: 'BEX-SHF', name: 'Subtropical Humid Forest' },
  { cpdId: 'BEX-TCF', name: 'Temperate Continental Forest' },
  { cpdId: 'BEX-TDD', name: 'Tropical Dry Deciduous' },
  { cpdId: 'BEX-TMD', name: 'Tropical Moist Deciduous' },
  { cpdId: 'BEX-TOR', name: 'Temperate Oceanic Rainforest' },
  { cpdId: 'BEX-TR', name: 'Tropical Rainforest' },
  { cpdId: 'BEX-TS', name: 'Tropical Shrublands' },
  { cpdId: 'BEX-TWSM', name: 'Temperate Wetland and Salt Marsh' }
]

export const generalSiteConditions = [
  { cpdId: 'PL-GP', name: 'General Planting  (shrubs, groundcover, etc)' }
]
export const lawnSiteConditions = [
  { cpdId: 'PL-INT', name: 'High Management' },
  { cpdId: 'PL-MOD', name: 'Moderate Management' },
  { cpdId: 'PL-MIN', name: 'Low Management' },
  { cpdId: 'PL-NM', name: 'No-mow' }
]

export const treeSiteConditions = [
  { cpdId: 'PT-LD-EX', name: 'Deciduous Large' },
  { cpdId: 'PT-MD-EX', name: 'Deciduous Medium' },
  { cpdId: 'PT-SD-EX', name: 'Deciduous Small' },
  { cpdId: 'PT-LE-EX', name: 'Evergreen Large' },
  { cpdId: 'PT-ME-EX', name: 'Evergreen Medium' },
  { cpdId: 'PT-SE-EX', name: 'Evergreen Small' }
]
