<template>
  <v-dialog
    v-model="isVisible"
    max-width="800"
    scrollable
  >
    <ValidationObserver
      v-if="isVisible"
      ref="observer"
      v-slot="{ handleSubmit, errors, dirty }"
    >
      <v-form
        class="d-flex flex-column"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card>
          <v-card-title>Biodiversity Site Conditions - Existing</v-card-title>
          <v-card-text>
            <v-divider />
            <v-row class="ma-0 pa-0">
              <v-col class="v-label">
                Total Site Area:
              </v-col>
              <v-col class="v-label">
                {{ parsedArea }}
              </v-col>
            </v-row>
            <v-row class="ma-0 pa-0">
              <v-col class="v-label">
                Enter the following existing site information, by:
              </v-col>
              <v-col>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="enterSiteInformationBy"
                  rules="required"
                >
                  <v-radio-group
                    v-model="updatedProject.enterSiteInformationBy"
                    name="enterSiteInformationBy"
                    class="ma-0 pa-0"
                    row
                    :error-messages="errors"
                  >
                    <v-radio
                      label="Area"
                      value="area"
                    />
                    <v-radio
                      label="Percentage"
                      value="percentage"
                    />
                  </v-radio-group>
                </ValidationProvider>
              </v-col>
            </v-row>
            <div v-if="updatedProject.enterSiteInformationBy">
              <v-row
                class="ma-0 pa-0"
              >
                <v-col class="v-label">
                  Hardscape and Buildings:
                </v-col>
                <v-col
                  align-self="start"
                  class="pt-0 pb-0 mt-0 mb-0"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Hardscape and Buildings"
                    :rules="hardscapeRules"
                  >
                    <AreaOrPercentOfInput
                      v-model.number="updatedProject.hardscapeAndBuildingsArea"
                      :max="area"
                      name="hardscape"
                      class="ma-0 pa-0 hardscape"
                      :type="updatedProject.enterSiteInformationBy"
                      reverse
                      hide-spin-buttons
                      :prefix="areaUnits"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <SiteConditionSection
                section-name="Ecosystem"
                :available-area="area"
                :show-percent-native="false"
                :show-condition="true"
                :v-model="allEcosystems"
                :rules="rules"
                :enter-site-information-by="updatedProject.enterSiteInformationBy"
                @input="(row) => updateProjectRow('ecosystems', row)"
              >
                <template v-slot:tooltip>
                  Select all existing ecosystems on site, from UN FAO Global Ecological Zones classification, see Resources.
                </template>
                <template v-slot:percentage-tooltip>
                  Select above to enter either areas (in m2 or sf) or percentage of overall pre-construction site comprised of native ecosystems.
                </template>
                <template v-slot:area-tooltip>
                  Select above to enter either areas (in m2 or sf) or percentage of overall pre-construction site comprised of native ecosystems.
                </template>
                <template v-slot:condition-tooltip>
                  Choose condition of existing ecosystem, compared to an optimal native ecosystem. Good (G) is close to optimal,
                  Moderate (M) shows some impacts, while a Poor (P) ecosystem shows heavy impacts of human interference,
                  invasive species, browsing and/or climate change. See Methodology Report for further information.
                </template>
              </SiteConditionSection>
              <SiteConditionSection
                section-name="General"
                :available-area="area"
                :show-percent-native="true"
                :show-condition="false"
                :v-model="allGeneralSiteConditions"
                :rules="rules"
                :enter-site-information-by="updatedProject.enterSiteInformationBy"
                @input="(row) => updateProjectRow('otherPlantingAreas', row)"
              >
                <template v-slot:tooltip>
                  General Planting refers to planting areas that are neither native
                  ecosystems nor lawn. This may include areas with trees, shrubs, groundcover,
                  perennials, etc. Individual trees should be added separately below.
                </template>
                <template v-slot:percentage-tooltip>
                  Select above to enter either areas (in m2 or sf) or percentage of overall pre-construction site comprised of native ecosystems.
                </template>
                <template v-slot:area-tooltip>
                  Select above to enter either areas (in m2 or sf) or percentage of overall pre-construction
                  site comprised of general landscape planting, including shrubs, groundcovers,
                  perennials, annuals, etc.
                </template>
                <template v-slot:native-tooltip>
                  Enter percentage of all existing plants that are native to the site's ecoregion. A native plant is a plant
                  that occurs naturally in the place where it evolved or dispersed without the benefit of human activity.  (see Benefits tab, Biodiversity).
                </template>
              </SiteConditionSection>
              <SiteConditionSection
                section-name="Lawn"
                :available-area="area"
                :show-percent-native="true"
                :show-condition="false"
                :v-model="allLawnSiteConditions"
                :rules="rules"
                :enter-site-information-by="updatedProject.enterSiteInformationBy"
                @input="(row) => updateProjectRow('otherPlantingAreas', row)"
              >
                <template v-slot:tooltip>
                  <p>Select existing areas of turf lawn:</p>
                  <p class="ml-2">
                    Intensive Management: mowing to 3 inches, intensive use of non-organic fertilizer and irrigation.
                  </p>
                  <p class="ml-2">
                    Moderate Management: mowing to 3 inches, moderate application of non-organic fertilizer and irrigation.
                  </p>
                  <p class="ml-2">
                    Minimal Management: mowing to 3 inches, no irrigation, organic fertilizer only.
                  </p>
                  <p class="ml-2">
                    No-mow: organic fertilizer only, no mowing, no irrigation
                  </p>
                </template>
                <template v-slot:percentage-tooltip>
                  Select above to enter either areas (in m2 or sf) or percentage of overall pre-construction site comprised of turf lawn. Select High Maintenance for.
                </template>
                <template v-slot:area-tooltip>
                  Select above to enter either areas (in m2 or sf) or percentage of overall
                  pre-construction site comprised of turf lawn.
                </template>
                <template v-slot:native-tooltip>
                  Enter percentage of total existing lawn area comprised of species native to the site's ecoregion.
                </template>
              </SiteConditionSection>
              <SiteConditionSection
                section-name="Trees"
                :available-area="area"
                :show-percent-native="true"
                :show-condition="false"
                :show-quantity="true"
                :v-model="allTreeSiteConditions"
                :rules="rules"
                :enter-site-information-by="updatedProject.enterSiteInformationBy"
                @input="(row) => updateProjectRow('otherPlantingAreas', row)"
              >
                <template v-slot:tooltip>
                  <p>Mature height of existing tree species:</p>
                  <p class="ml-2">
                    Large: above 50ft (15m)
                  </p>
                  <p class="ml-2">
                    Medium: 35-50ft (10-15m)
                  </p>
                  <p class="ml-2">
                    Small: below 35ft (10m)
                  </p>
                </template>
                <template v-slot:native-tooltip>
                  Enter percentage of total number of existing trees of each size and type that are native to the ecoregion (see Benefits, Biodiversity).
                </template>
              </SiteConditionSection>
            </div>
            <FormErrors
              class="mt-4"
              :vee-errors="errors"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="mx-2 mb-3"
              type="button"
              elevation="0"
              @click="reset"
            >
              Discard
            </v-btn>
            <v-btn
              class="mx-2 mb-3"
              color="shamrock white--text"
              type="submit"
              :loading="loading"
              :disabled="!dirty"
              elevation="0"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { ecosystems, generalSiteConditions, lawnSiteConditions, treeSiteConditions } from './constants'
import SiteConditionSection from './SiteConditionSection'
import unitTools from '../../../mixins/unitTools'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import FormErrors from '@/components/form/FormErrors'
import AreaOrPercentOfInput from '@/components/form/AreaOrPercentOfInput'

const { mapGetters, mapActions } = createNamespacedHelpers('projects')

export default {
  name: 'ProjectSettings',
  components: { FormErrors, SiteConditionSection, ValidationObserver, ValidationProvider, AreaOrPercentOfInput },
  mixins: [unitTools],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      updatedProject: null,
      loading: false,
      deleteMenuVisible: false,
      deleting: false,
      isVisible: this.visible
    }
  },
  computed: {
    ...mapGetters(['project']),
    allEcosystems () {
      return ecosystems
        .map((ecosystem) => ({ ...ecosystem, ...(this.updatedProject.ecosystems || []).find(({ name }) => name === ecosystem.name) }))
        .map((ecosystem) => {
          if (ecosystem.cpdId) {
            return { ...ecosystem, name: this.$t(`existingConditions.ecosystems.${ecosystem.cpdId}`) }
          }
          return ecosystem
        })
    },
    allGeneralSiteConditions () {
      return generalSiteConditions
        .map((general) => ({ ...general, ...(this.updatedProject.otherPlantingAreas || []).find(({ name }) => name === general.name) }))
        .map((general) => ({ ...general, name: this.$t(`existingConditions.generalSiteConditions.${general.cpdId}`) }))
    },
    availableArea () {
      return this.project.location.area - (this.updatedProject.hardscapeAndBuildingsArea || 0) || 0
    },
    rules () {
      const totalArea = [
        ...this.updatedProject?.ecosystems, ...this.updatedProject?.otherPlantingAreas
      ].reduce((previous, ecosystem) => {
        return (ecosystem.active && ecosystem.name ? (Number(ecosystem.area) || 0) : 0) + previous
      }, 0)

      const fuzzy = this.area * 0.005

      return {
        area: {
          min_value: 0,
          sum_to_large: { remaining: this.availableArea - totalArea, fuzzy: fuzzy },
          sum_to_small: { remaining: this.availableArea - totalArea, fuzzy: fuzzy }
        }
      }
    },
    hardscapeRules () {
      return {
        required: true,
        max_value: this.area
      }
    },
    allLawnSiteConditions () {
      return lawnSiteConditions
        .map((lawn) => ({ ...lawn, ...(this.updatedProject.otherPlantingAreas || []).find(({ name }) => name === lawn.name) }))
        .map((lawn) => {
          if (lawn.cpdId) {
            return { ...lawn, name: this.$t(`existingConditions.lawnSiteConditions.${lawn.cpdId}`) }
          }
          return lawn
        })
    },
    allTreeSiteConditions () {
      return treeSiteConditions.map((tree) =>
        ({ ...tree, ...(this.updatedProject.otherPlantingAreas || []).find(({ name }) => name === tree.name) })
      ).map((tree) => {
        if (tree.cpdId) {
          return { ...tree, name: this.$t(`existingConditions.treeSiteConditions.${tree.cpdId}`) }
        }
        return tree
      })
    },
    area () {
      return this.project?.location?.area || 0
    },
    parsedArea () {
      if (!this.project) return ''
      const val = this.project.location.area
      return this.getUnitVal(val, 'm2', true).str
    }
  },
  watch: {
    visible (val) {
      this.isVisible = val
    },
    isVisible: {
      immediate: true,
      handler (val) {
        this.$emit('setVisible', val)
        this.updatedProject = JSON.parse(JSON.stringify(this.project.existingBiodiversitySiteConditions))
        this.updatedProject.hardscapeAndBuildingsArea = this.updatedProject.hardscapeAndBuildingsArea || 0
      }
    }
  },
  created () {
    const dictionary = {
      fields: {
        'Hardscape and Buildings': {
          max_value: 'Hardscape and Buildings Area is larger than total site area'
        }
      }
    }
    localize('en', dictionary)
  },
  methods: {
    ...mapActions(['updateProject', 'deleteProject']),
    async onSubmit () {
      this.loading = true
      const payload = { existingBiodiversitySiteConditions: this.updatedProject }
      await this.updateProject(payload)
      this.loading = false
      this.$refs.observer.reset()
      this.$emit('setVisible', false)
    },
    updateProjectRow (type, row) {
      this.updatedProject[type] = [...(this.updatedProject[type] || []).filter(({ name }) => name !== row.name), row]
    },
    reset () {
      this.updatedProject = this.project.existingBiodiversitySiteConditions
      this.$refs.observer.reset()
      this.$emit('setVisible', false)
    }
  }
}
</script>
<style>
.hardscape {
  width: 150px;
}
</style>
