<template>
  <v-container
    class="pt-7"
    :class="$vuetify.breakpoint.xsOnly ? 'px-5' : 'px-8'"
    :style="{ overflow: visible ? 'auto' : 'hidden' }"
  >
    <v-row>
      <v-col>
        <div>
          <p
            class="font-weight-light shamrock--text"
            :class="$vuetify.breakpoint.xsOnly ? ['headline', 'mb-2'] : ($vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2')"
          >
            Improve your project’s carbon impact
          </p>
        </div>
        <div v-if="visible">
          <p
            class="font-weight-light metal--text"
            :class="$vuetify.breakpoint.xsOnly ? ['title', 'mb-2'] : ($vuetify.breakpoint.smAndDown ? 'headline' : 'display-1')"
          >
            <span v-if="categories.length === 0">
              Add further detail to your project to get started.
            </span>
            <span v-else>
              Consider these alternatives:
            </span>
          </p>
          <div
            v-if="loading"
            class="mt-8 text-center"
          >
            <v-progress-linear
              color="shamrock accent-4"
              indeterminate
              rounded
              height="6"
            />
            <div class="title">
              ...Loading Alternatives
            </div>
          </div>
          <div v-else>
            <div style="margin: 0 -10px;">
              <HighLevelSummary
                headline="Add more trees, protected and restored ecosystems"
                description="Design multi-layered planting based on local native habitats to increase sequestration and biodiversity."
              />
            </div>
            <CategorySuggestionsCard
              v-for="(category, index) of suggestionsCategories"
              :key="index"
              :index="index"
              :category="category"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HighLevelSummary from './HighLevelSummary'
import CategorySuggestionsCard from './CategorySuggestionsCard'

export default {
  name: 'DesignSuggestions',
  components: {
    HighLevelSummary,
    CategorySuggestionsCard
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    suggestionsCategories () {
      return this.categories
        .map(x => x.suggestionsCategories?.map?.((v, i) => ({ ...v, _index: i })) || x.suggestionsCategories).flat()
        .filter(x => x.elements ? x.elements.length > 0 : true)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
